.Form {
  margin: 10% 30%;
}

.FormSettings {
  margin: 2% 5%;
}

.modalButton {
  margin: 20px;
}

.charts {
  margin-left: 30px;
}

.chart {
  width: 90%;
}

.Table {
  margin-top: 3%;
}

.calendar {
  margin-top: 10%;
}
